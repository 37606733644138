









































import Input from '@/components/carSearch/Input.vue';
import LinkButton from '@/components/carSearch/LinkButton.vue';
import { Lang, transliterateCarNumber } from '@/utils/transliterate';

const SHORT_REGION_NUMBER_LENGTH = 8;
const DELAY = 1000;

export default {
  name: 'CarSearch',
  components: { LinkButton, Input },
  data() {
    return {
      carNumber: '',
      normalizedNumber: '',
      isDelayActive: false,
    };
  },
  computed: {
    isSearchButtonDisabled() {
      return (
        this.isDelayActive ||
        this.normalizedNumber.length < SHORT_REGION_NUMBER_LENGTH
      );
    },
  },
  methods: {
    onInput(value: string) {
      this.carNumber = value.toUpperCase().replaceAll(' ', '');
      this.normalizedNumber = transliterateCarNumber(
        this.carNumber,
        Lang.EN,
      ).replaceAll(' ', '');
      if (this.normalizedNumber.length === SHORT_REGION_NUMBER_LENGTH) {
        this.isDelayActive = true;
        setTimeout(() => {
          this.isDelayActive = false;
        }, DELAY);
      }
      if (this.normalizedNumber.length > SHORT_REGION_NUMBER_LENGTH) {
        this.isDelayActive = false;
      }
    },
  },
};
