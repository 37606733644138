<template>
  <a
    class="LinkButton"
    :href="href"
    :class="computedClass"
    @click="$emit('click')"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'LinkButton',
  props: {
    href: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    computedClass() {
      return {
        disabled: this.disabled,
      };
    },
  },
};
</script>

<style scoped lang="less">
.LinkButton {
  display: block;
  padding: 20px;
  width: 100%;
  height: 64px;
  background: #1f2021;
  border-radius: 20px;
  font-family: 'EuclidCircularA-Regular', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #28e1b9;
  text-align: center;
  text-decoration: none;

  &.disabled {
    background: #eaecee;
    color: #999c9e;
    pointer-events: none;
  }
}
</style>
