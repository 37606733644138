









export default {
  name: 'Input',
  props: {
    transparent: {
      type: Boolean,
      required: false,
    },
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    onInput(e: InputEvent) {
      this.$emit('input', (e.target as HTMLInputElement).value);
    },
  },
};
