export enum Lang {
  RU = 'RU',
  EN = 'EN',
}

export function transliterateCarNumber(
  carNumber: string,
  targetLang?: Lang,
): string {
  if (!carNumber || typeof carNumber !== 'string') return carNumber;

  if (targetLang === Lang.EN) {
    return carNumber
      .replaceAll('у', 'y')
      .replaceAll('к', 'k')
      .replaceAll('е', 'e')
      .replaceAll('н', 'h')
      .replaceAll('х', 'x')
      .replaceAll('в', 'b')
      .replaceAll('а', 'a')
      .replaceAll('р', 'p')
      .replaceAll('о', 'o')
      .replaceAll('с', 'c')
      .replaceAll('м', 'm')
      .replaceAll('т', 't')
      .replaceAll('У', 'Y')
      .replaceAll('К', 'K')
      .replaceAll('Е', 'E')
      .replaceAll('Н', 'H')
      .replaceAll('Х', 'X')
      .replaceAll('В', 'B')
      .replaceAll('А', 'A')
      .replaceAll('Р', 'P')
      .replaceAll('О', 'O')
      .replaceAll('С', 'C')
      .replaceAll('М', 'M')
      .replaceAll('Т', 'T');
  } else {
    return carNumber
      .replaceAll('y', 'у')
      .replaceAll('k', 'к')
      .replaceAll('e', 'е')
      .replaceAll('h', 'н')
      .replaceAll('x', 'х')
      .replaceAll('b', 'в')
      .replaceAll('a', 'а')
      .replaceAll('p', 'р')
      .replaceAll('o', 'о')
      .replaceAll('c', 'с')
      .replaceAll('m', 'м')
      .replaceAll('t', 'т')
      .replaceAll('Y', 'У')
      .replaceAll('K', 'К')
      .replaceAll('E', 'Е')
      .replaceAll('H', 'Н')
      .replaceAll('X', 'Х')
      .replaceAll('B', 'В')
      .replaceAll('A', 'А')
      .replaceAll('P', 'Р')
      .replaceAll('O', 'О')
      .replaceAll('C', 'С')
      .replaceAll('M', 'М')
      .replaceAll('T', 'Т');
  }
}
